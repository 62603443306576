body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slider {
  /* background: red; */
  display: flex !important;
  align-items: center;
  height: 100%;
}

.slick-list {
  flex: auto;
}